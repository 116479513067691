<template>
  <v-tooltip right>
    <template v-slot:activator="{ on }">
      <span v-on="on" @click.prevent.stop="clicked" style="text-decoration: underline; font-family: monospace;">{{ long ? id : `&hellip;${id.substr(id.length-4)}`}}</span>
    </template>
    <div v-if="!long" style="font-size: 16px; font-family: monospace;">{{ id }}</div>
    <span v-if="copied">Copied!</span>
    <span v-else>Click to copy</span>
  </v-tooltip>
</template>

<script>
import clipboard from '@/services/clipboard';

export default {
  props: {
    id: String,
    long: Boolean,
  },
  data() {
    return {
      copied: false,
    };
  },
  methods: {
    clicked() {
      clipboard(this.id);
      this.copied = true;
      setTimeout(() => {
        this.copied = false;
      }, 2000);
    },
  },
};
</script>
